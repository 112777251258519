.form-container {
	margin-top:20px;
	margin-bottom:10px;
	padding:20px;
	border-radius:4px;
	box-shadow:0 2px 2px rgba(204,197,185,.5);
	background:#fff;
	position:relative;
	z-index:1;
	zoom:1;
	overflow:hidden;
}
.form-container .cell {
	margin:0 20px 20px 0;
}
.form-container .cell label span {
	display:block;
	margin:0 0 5px 0;
	color:#777;
}
.form-container .cell-oneline {
	display:flex;
	flex-direction:row;
	align-items:center;
}
.form-container .cell-oneline .cell {
	width:200px;
}
.form-container .row {
	display:flex;
	justify-content:space-between;
	align-items:center;
	padding:10px 0;
	border-bottom:1px solid #ccc;
}
.form-container .row span.category {
	display:block;
	color:#999;
}
.form-container .row-total {
	padding:15px 5px 10px;
	display:flex;
	flex-direction:row;
	justify-content:space-between;
	align-items:center;
}
.form-container .row-total div b {
	font-size:130%;
}
.form-container .row div:first-child .item-weight {
	margin-left:10px;
	color:#999;
	font-size:90%;
}
.container {
	display:flex;
	flex:1;
	flex-direction:row;
	width:100%;
	background:#f4f3ef;
	overflow:hidden;
}
.container .main {
	flex:1;
	padding:15px;
	padding-top:0;
	overflow:auto;
}
.container .menu {
	width:240px;
	height:100%;
    padding:0 10px 0;
	background:#fff;
	box-shadow:0 2px 2px rgba(204,197,185,.5);
}
.container .main h1 {font-size:28px;}
.container .menu img.logo {
	margin:30px 0 20px;
	width:200px;
}
.container .menu ul {
	margin:0;
}
.container .menu ul li {
	padding:0;
}
.container .menu ul li b {
	display:block;
	margin-top:20px;
	padding-left:5px;
}
.container .menu ul li a {
	display:block;
	text-decoration:none;
	color:#555;
	padding:2px 5px;
}
.container .menu ul li:hover a {
	color:#000;
	border-radius:4px;
	background:#eee;
}
.container .menu ul li a.selected {
	background:#555;
	border-radius:4px;
	color:#fff;
}
.container .menu ul li.partner-info {
	margin:20px 0;
	padding:10px;
	background:#eee;
	border-radius:5px;
}
.container .menu ul li .partner-name {
	font-size:110%;
	line-height:1.1;
}
.container .menu .logoff {
	margin-top:50px;
}
.container .menu .logoff a {
	font-size:100%;
	color:#777;
	text-decoration:none;
}

.container .menu ul li .new-order {
	display:inline-block;
	width:8px;
	height:8px;
	border-radius:8px;;
	margin-left:5px;
	background:#c00;
}


.confirmcontainer {
	width:340px;
	padding:20px;
	border-radius:5px;
	background:#fff;
}
.confirmcontainer h4 {
	margin:0 0 20px 0 !important;
	font-weight:normal;
}
.confirmcontainer .buttons {
	display:flex;
	justify-content:flex-end;
	align-items:center;
	margin-top:30px;
}
.confirmcontainer .buttons button {
	border:none;
    background:#ea6060;
    color:#fff;
	padding:8px 16px;
	margin-right:30px;
    border-radius:4px;
}
.confirmcontainer .buttons button, .confirmcontainer .buttons a {
	cursor:pointer;
	text-decoration:none;
}
.confirmcontainer .buttons a {color:#555;}
.container .menu-open {
	display: none;
	justify-content: center;
	align-items: center;
	position: fixed;
    top: 2%;
    left: 3%;
}
.container .menu-open img {
	width: 30px;
}
.container .menu .menu-close {
	position: absolute;
	top: 3%;
	right: 7%;
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 0;
	display: none;
}
.container .menu .menu-close img {
	width: 30px;
}
@media (max-width: 768px) {
	
	.container .main {
		padding: 0;
		margin-top: 20%;
	}
	.container .menu {
		position: relative;
		width: 0;
		padding: 0;
		overflow: hidden;
		transition: width 0.3s, padding 0.3s;
		z-index: 10;
		padding-bottom: 30px;
	}
	.container .menu img {
		margin:20px 0 0;
		width:160px;
	}
	.container .menu ul li a {
		padding:3px 0;
	}
	.container .menu .menu-close {
		display: initial;
	}
	.container .menu.active {
		padding: 0 20px 30px;
		width: 75%;
		overflow-y: auto;
		box-shadow: 16px 0 10px rgba(0, 0, 0, 0.1);
	}
	.container .menu-open {
		display: flex;
	}
	.confirmcontainer {
		width: 90%;
		margin: 0 auto;
	}
}
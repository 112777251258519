.menu-block {
	display:flex;
	flex-direction:row;
}
.menu-block .orders {
	position:fixed;
	padding-top:30px;
	width:240px;
	border-right:1px solid #ccc;
}
.menu-block .orders ul li {
	position:relative;
	margin:0 10px 5px 0;
}
.menu-block .orders ul li a {
	display:block;
	padding:15px 10px;
	cursor:pointer;
	text-decoration:none;
	color:#333;
	font-size:110%;
	background:#fff;
	border-radius:12px;
}
.menu-block .orders ul li.active a {
	background:#ddd;
}
.menu-block .orders ul li.alert a {
	background:#c00;
}
.menu-block .orders ul li.alert * {
	color:#fff;
}
.menu-block .orders ul li.alert-new a {
	background:#ffdb4d;
}
.menu-block .orders ul li div {
	font-size:90%;
	color:#999;
}
.menu-block .orders h4 {
	margin-left:10px;
}
.menu-block .orders .orders-items {
	padding-top:20px;
	height:calc(100vh - 50px);
	overflow-y:auto;
}
.menu-block .order-info {
	max-width:800px;
	width:100%;
	height:auto;
	margin:30px 0 0 240px;
}
.menu-block .order-info .order-info-inner {
	padding:0 20px;
}
.menu-block .order-info .block {
	margin-bottom:20px;
}
.menu-block .order-info .block h4 {
	margin-left:5px;
	margin-bottom:5px;
	display:flex;
	flex-direction:row;
	align-items:center;
	justify-content:space-between;
}
.menu-block .order-info .block h4 span {
	font-weight:normal;
	font-size:80%;
	color:#999;
	margin-right:5px;
}
.menu-block .order-info .row {
	display:flex;
	flex-direction:row;
	padding:10px 5px;
	border-bottom:1px solid #ccc;
}
.menu-block .order-info .row div:first-child {
	width:240px;
}
.menu-block .order-info .row-check {
	padding:10px 5px;
	border-bottom:1px solid #ccc;
	display:flex;
	flex-direction:row;
	justify-content:space-between;
	align-items:flex-start;
}
.menu-block .order-info .row-check-last {
	border:none;
}
.menu-block .order-info .row-check div:first-child .item-category {
	display:block;
	font-size:90%;
	color:#777;
}
.menu-block .order-info .row-check div:first-child .item-weight {
	margin-left:10px;
	color:#999;
	font-size:90%;
}
.menu-block .order-info .row-check div:first-child .item-name {
	font-weight:500;
}
.menu-block .order-info .row-check div:last-child {
	font-size:110%;
	font-weight:500;
}
.menu-block .order-info .row-check div a {
	font-size:80%;
	font-weight:bold;
	color:#00c;
	cursor:pointer;
}
.menu-block .order-info .row-total {
	padding:5px 5px 10px;
	display:flex;
	flex-direction:row;
	justify-content:space-between;
	align-items:center;
}
.menu-block .order-info .row-total div b {
	font-size:130%;
}
.menu-block .order-info .row-delivery {
	padding:10px 5px 0;
	color:#999;
}
.menu-block .order-info .not-selected {
	margin:0;
	padding:0 20px;
}
.menu-block .order-info .not-selected p {
	margin-top:10px;
	color:#999;
}
.menu-block .order-info .buttons {
	border-top:1px solid #ccc;
	padding:10px 20px;
}
.menu-block .order-info .buttons button {
	width:100%;
	height:40px;
	background:#333;
	padding:0 20px;
	color:#fff;
	font-size:16px;
	border:none;
	border-radius:3px;
}
.menu-block .order-info .buttons button:hover {
	opacity:.8;
	cursor:pointer;
}
.menu-block .order-info .buttons button div {
	font-weight:normal;
	font-size:12px;
}
.menu-block .order-info .buttons button.button-cancel {
	display:block;
	width:180px;
	margin-left:10px;
	text-align:center;
	background:#ccc;
	color:#000;
}
.orders-empty {
	max-width:860px;
	height:90%;
	display:flex;
	align-items:center;
	justify-content:center;
}
.show-map {
	display:flex;
	flex-direction:row;
	align-items:center;
	font-size:90%;
	color:#555;
	cursor:pointer;
	margin-left:auto;
}
.courier-map {
	position:relative;
	width:100%;
	height:240px;
	background:#eee url('../../Assets/Images/loading.svg') 50% 50% no-repeat;
	margin-bottom:20px;
}
.courier-map-notfound {
    position:absolute;
	left:50%;
	bottom:0;
    width:300px;
    display:flex;
    padding:10px 12px;
    transform: translate(-50%, -100%);
    background:#fff;
    box-shadow:0 4px 12px rgba(0,0,0,.1);
    border-radius:10px;
	font-size:80%;
	color:#555;
	z-index:10000;
}